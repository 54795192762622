<template>
  <div>
    <h3 class="title is-3 is-marginless">Reserve Your Practice Category</h3>
    <div class="columns">
      <div class="column">
        <span>Select your practice category below, or create your own.
          Only one participant per category so reserve your space now.
          Your selection will be reserved for five days.
        </span>
        <hr>
        <div class="columns is-mobile is-multiline">
          <FaceCategory
            class="column is-4-desktop is-6-touch"
            v-for="category in categories"
            :key="category.title"
            :category="category"
            @new-value="updateSelected"
          />
        </div>
      </div>
      <div class="column is-3 is-hidden-mobile" :class="{ 'is-hidden': confirmed.length === 0 }">
        <ConfirmedFaces :confirmed="confirmed" />
      </div>
    </div>
    <div class="columns container alternative-face">
      <div class="column is-8 is-offset-2">
        <p class="is-marginless has-text-weight-bold is-size-7">OR</p>
        <p class="has-text-weight-light is-size-5">
          Create your own branding message:
        </p>
        <div class="field has-text-left">
          <label for="customFaceOf" class="label is-normal is-size-7">
            THE FACE OF...
          </label>
          <div class="control">
            <FormInput
              :field="{
                name: 'customFaceOf',
                type: 'text',
                validation: {
                  required: false,
                },
              }"
              @click.native="selected = null"
            />
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import slug from '@/lib/slug';
import { FormInput } from 'djx-form';

export default {
  data() {
    return {
      taken: [],
      confirmed: [],
      selected: null,
    };
  },
  computed: {
    ...mapState({
      rawCategories: 'faces',
      projectId: 'project_id',
    }),
    categories() {
      return this.rawCategories.map((category) => ({
        title: category,
        taken: this.taken.includes(this.slugify(category)),
        selected: this.selected === this.slugify(category),
      }));
    },
  },
  methods: {
    slugify(string) {
      return slug(string);
    },
    updateSelected(newValue) {
      if (!this.taken.includes(newValue)) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    // get the taken faces from the API and populate this.taken and this.confirmed
    window.fetch(`https://dj5-api.datajoe.com/api/survey/faces/${this.projectId}`, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => res.json())
      .then(({ taken, confirmed }) => {
        this.taken = taken;
        this.confirmed = confirmed;
      });
  },
  components: {
    FormInput,
    FaceCategory: () => import('@/components/FacesOf/FaceCategory.vue'),
    ConfirmedFaces: () => import('@/components/FacesOf/ConfirmedFaces.vue'),
  },
};
</script>
